import React from "react"

import Section from "../sections/section"

const GraphQL = () => {
  const { getStrapiQuerySchema } = require(`../data/graphql-queries`)

  const strapiQuerySchema = getStrapiQuerySchema({
    queryBy: "gatsby",
  })

  // console.log(strapiQuerySchema)

  return (
    <Section background="LIGHT">
      <pre>{strapiQuerySchema}</pre>
    </Section>
  )
}

export default GraphQL
